document.addEventListener('DOMContentLoaded', function() {
    function closeNavbar() {
        const navbarCollapse = document.getElementById('navbarNav3');
        const bsCollapse = new bootstrap.Collapse(navbarCollapse, {
            toggle: false
        });

        bsCollapse.hide();
    }

    const navLinks = document.querySelectorAll('#navbarNav3 .nav-link');
    navLinks.forEach(link => {
        link.addEventListener('click', closeNavbar);
    });
});
