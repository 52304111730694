'use strict';

(function () {
  const $smoothScroll = $('.smooth-scroll');

  // Slide Custom
  const customSlides = $(".custom-slide > .slides");
  const customSlidesThumbs = $('.custom-slide-thumbs > .slides');

  $smoothScroll.on('click', function (ev) {
    ev.preventDefault();
    var elem = this;
    $(elem).addClass('active').parent().siblings().children().removeClass('active');

    if (window.location.pathname === '/') {
      //if its home page, complete normal smooth scroll to an anchor
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(elem.hash);
        target = target.length ? target : $('[name=' + elem.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000);
          return false;
        }
      }
    } else {
      //if its not home page, redirect to home page anchor
      var urlPath = window.location.pathname.indexOf("app_dev.php") != -1 ? "/app_dev.php/" : "/";
      window.location = urlPath + elem.hash;
      return;
    }
  });

  var MapModule = (function () {

    var $markerLink = $('.marker-links .item-dpto');

    var $sucursalesList = $('.sucursales-selected-list');

    var map;
    // Config Zoom
    var zoomOut, zoomIn;

    // Makers, marker pin image, locations, center map
    var markers = [];
    var marker_image;

    var centerMap = {
      lat: '',
      long: ''
    };

    // Message widget
    var infoWindowHTML = '<div class=\"infowindow-content\">' +
      '<h5 class=\"infowindow-title\"> %infoTitle% </h5>' +
      '<p>%infoContent%</p></div>';

    function _initMap(elem) {
      var $e = $(elem);

      //if element not exist, return

      if ($e.length == 0) {
        return;
      }

      //Default center
      centerMap.lat = parseFloat($e.attr('data-map-lat') || -32.522779);
      centerMap.long = parseFloat($e.attr('data-map-long') || -55.76583500000004);

      zoomOut = parseInt($e.attr('data-map-zoom-out') || 6);
      zoomIn = parseInt($e.attr('data-map-zoom-in') || 10);

      marker_image = $e.attr('data-marker-image') || '/img/map-marker.png';


      //Map start init
      var mapOptions = {
        center: new google.maps.LatLng(centerMap.lat, centerMap.long),
        zoom: zoomOut,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.DEFAULT,
        },
        disableDoubleClickZoom: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        scaleControl: true,
        scrollwheel: false,
        streetViewControl: true,
        draggable: true,
        overviewMapControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,

        //color, lines, routes, water color
        styles: []
      };

      map = new google.maps.Map($e.get(0), mapOptions);

      _createMarkers();

    }

    function _createMarkers() {
      $.each(locations, function (key, departamento) {
        $(departamento).each(function (index, sucursal) {

          if(sucursal.latitud && sucursal.longitud) {
            var marker = new google.maps.Marker({
              name: sucursal.departamento,
              position: {
                lat: Number(sucursal.latitud),
                lng: Number(sucursal.longitud)
              },
              map: map,
              icon: marker_image
            });

            var infowindow = new google.maps.InfoWindow({
              content: infoWindowHTML.replace('%infoTitle%', sucursal.nombre).replace('%infoContent%', sucursal.direccion)
            });

            markers.push(marker);

            _addListenerMarker(marker, infowindow);
          }

        });
      });
    }

    function _addListenerMarker(marker, infowindow) {
      marker.addListener('click', function () {
        map.setZoom(zoomIn);
        map.setCenter(marker.getPosition());
        infowindow.open(map, marker);
      });
    }

    function _addListenerLinkMarker() {
      $markerLink.on('click', function (ev) {
        $(this).addClass('active').siblings().removeClass('active');
        ev.preventDefault();

        _renderSucursales($(this).data('departamento'));
        _removeMarkers($(this).data('departamento'));
        _renderMarkers($(this).data('departamento'));

        var myLatLng = {
          lat: $(this).data('map-lat') || centerMap.lat,
          lng: $(this).data('map-long') || centerMap.long
        };
        map.setCenter(myLatLng);
        map.setZoom(zoomIn);
      });
    }

    function _renderMarkers(departamento) {
      var renderMarkers = markers.filter(function (value, index) {
        return value.name === departamento;
      });

      renderMarkers.forEach(function (value, index) {
        value.setMap(map);
      })
    }

    function _removeMarkers(departamento) {
      var deletedMarkers = markers.filter(function (value, index) {
        return value.name !== departamento;
      });

      deletedMarkers.forEach(function (value, index) {
        value.setMap(null);
      });
    }

    function _renderSucursales(departamento) {
      var sucursales = locations[departamento];
      var sucursalHTML = '';

      $sucursalesList.empty();

      $(sucursales).each(function (key, sucursal) {
        sucursalHTML += '<li class="list-group-item"><h4>' + sucursal.nombre + '</h4><small> Dirección: ' + sucursal.direccion + ' - Tel: ' + sucursal.telefono + ' </small></li>';
      });
      $sucursalesList.append(sucursalHTML);
    }

    _addListenerLinkMarker();

    return {
      init: _initMap
    };
  })();


  window.initMap = function () {
    MapModule.init('.map');
  };

})();
